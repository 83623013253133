import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./navbar.css";
import {
  HouseDoorFill,
  FileEarmarkPerson,
  Grid3x3,
  PersonLinesFill,
  PersonWorkspace,
} from "react-bootstrap-icons";
import ProgressBar from "./ProgressBar";
import MobileNav from "./MobileNav";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  const toHome = () => {
    if (location.pathname !== "/") {
      navigate("/");
      window.location.reload();
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <header className="page-header">
        <nav className="page-navbar">
          <div className="page-logo">
            <NavLink to="/" className="logo-link" onClick={toHome}>
              <img src="logo.png" alt="Appharu Logo" />
            </NavLink>
          </div>
          <div className="nav-bg">
            <ul className="page-header-links">
              <li className="page-header-link">
                <NavLink to="/" className="link" onClick={toHome}>
                  <HouseDoorFill />
                  <span>Home</span>
                </NavLink>
              </li>
              <li className="page-header-link">
                <NavLink to="/about-us" className="link">
                  <FileEarmarkPerson />
                  <span>About</span>
                </NavLink>
              </li>
              <li className="page-header-link">
                <NavLink to="/services" className="link">
                  <Grid3x3 />
                  <span>Services</span>
                </NavLink>
              </li>
              <li className="page-header-link">
                <NavLink to="/contact" className="link">
                  <PersonLinesFill />
                  <span>Contact</span>
                </NavLink>
              </li>
              <li className="page-header-link">
                <NavLink to="/projects" className="link">
                  <PersonWorkspace />
                  <span>Projects</span>
                </NavLink>
              </li>
              {/* <li className="page-header-link">
              <NavLink to="#" className="link">
                <Globe />
                <span>Domain</span>
              </NavLink>
            </li>
            <li className="page-header-link">
              <NavLink to="#" className="link">
                <InfoCircle />
                <span>Support</span>
              </NavLink>
            </li> */}
            </ul>
          </div>
          <div className="nav-toggler" onClick={() => setOpen(!open)}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </nav>
        {open && <MobileNav open={open} setOpen={setOpen} toHome={toHome} />}
      </header>
      <ProgressBar />
    </>
  );
};

export default Navbar;
