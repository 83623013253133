import React, { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <div className="not-found-section">
      <h1 className="not-found">404</h1>
      <span>Oops!! Page Not Found</span>
    </div>
  );
};

export default NotFound;
