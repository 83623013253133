import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "../App.css";

const BackToTop = () => {
  const [top, setTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setTop(true);
      } else {
        setTop(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="back-to-top-sec">
      {top && (
        <button className="to-top-btn" onClick={scrollUp}>
          <Icon.ArrowUpShort />
        </button>
      )}
    </div>
  );
};

export default BackToTop;
