import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  X,
  HouseDoorFill,
  FileEarmarkPerson,
  Grid3x3,
  PersonLinesFill,
  PersonWorkspace,
} from "react-bootstrap-icons";

const MobileNav = ({ open, setOpen, toHome }) => {
  const [active, setActive] = useState("mob-nav-bg");

  const menuRef = useRef();

  useEffect(() => {
    open = true
      ? setActive("mob-nav-bg mob-nav-bg-active")
      : setActive("mob-nav-bg");
  }, [open]);

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handler, !open);

    return () => {
      document.removeEventListener("click", handler, !open);
    };
  });

  return (
    <div className="mob-nav-bg">
      <ul className="mob-nav" ref={menuRef}>
        <div className="nav-close" onClick={() => setOpen(!open)}>
          <X />
        </div>
        <li className="page-header-link">
          <NavLink to="/" className="link" onClick={toHome}>
            <HouseDoorFill />
            <span>Home</span>
          </NavLink>
        </li>
        <li className="page-header-link">
          <NavLink
            to="/about-us"
            className="link"
            onClick={() => setOpen(!open)}
          >
            <FileEarmarkPerson />
            <span>About</span>
          </NavLink>
        </li>
        <li className="page-header-link">
          <NavLink
            to="/services"
            className="link"
            onClick={() => setOpen(!open)}
          >
            <Grid3x3 />
            <span>Services</span>
          </NavLink>
        </li>
        <li className="page-header-link">
          <NavLink
            to="/contact"
            className="link"
            onClick={() => setOpen(!open)}
          >
            <PersonLinesFill />
            <span>Contact</span>
          </NavLink>
        </li>
        <li className="page-header-link">
          <NavLink
            to="/projects"
            className="link"
            onClick={() => setOpen(!open)}
          >
            <PersonWorkspace />
            <span>Projects</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default MobileNav;
