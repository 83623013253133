import { ArrowRight, Facebook, Linkedin, Github, HouseFill, Building, GeoAltFill, TelephoneFill } from "react-bootstrap-icons";

import { Link } from "react-router-dom";
import "aos/dist/aos.css";

const Footer = () => {
  return (
    <footer className="page-footer">
      <img src="./images/shapes/dots.png" alt="Dots" />
      <img src="./images/shapes/close.png" alt="Close" />
      <img src="./images/shapes/triangle.png" alt="Triangle" />
      <img src="./images/shapes/circle.png" alt="Circle" />
      <div className="footer-section container">
        <div className="footer-top">
          <Link to="/" className="footer-logo" data-aos="fade-right">
            <span className="logo-icon">
              <img className="f-logo" src="logo.png" alt="Logo" />
            </span>
            <span>AppHaru</span>
          </Link>
          <ul className="footer-links">
            <li className="footer-link" data-aos="fade-left">
              <Link to="/about-us">
                <span>About</span>
                <ArrowRight />
              </Link>
            </li>
            <li
              className="footer-link"
              data-aos="fade-left"
              data-aos-delay="150"
            >
              <Link to="/services">
                <span>Services</span>
                <ArrowRight />
              </Link>
            </li>
            <li
              className="footer-link"
              data-aos="fade-left"
              data-aos-delay="300"
            >
              <Link to="/contact">
                <span>Contact</span>
                <ArrowRight />
              </Link>
            </li>
            <li
              className="footer-link"
              data-aos="fade-left"
              data-aos-delay="450"
            >
              <Link to="/privacy-policy">
                <span>Privacy Policy</span>
                <ArrowRight />
              </Link>
            </li>
            <li
              className="footer-link"
              data-aos="fade-left"
              data-aos-delay="600"
            >
              <Link to="/financial-policy">
                <span>Financial Policy</span>
                <ArrowRight />
              </Link>
            </li>
            <li
              className="footer-link"
              data-aos="fade-left"
              data-aos-delay="750"
            >
              <Link to="/career">
                <span>Careers</span>
                <ArrowRight />
              </Link>
            </li>
            <li
              className="footer-link"
              data-aos="fade-left"
              data-aos-delay="900"
            >
              <Link to="/faq">
                <span>FAQ</span>
                <ArrowRight />
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-btm">
          <div>
            <div className="d-flex gap-2 align-items-center">
              <img className="flag-icon" src="/images/flags/nepal.png" alt="" />
              <h5 className="m-0">Nepal</h5>

            </div>
            <div>
              <p className="m-0 my-2 text-s"><Building /> AppHaru PVT. LTD.</p>
              <a target="_blank" href="https://www.google.com/maps/place/Appharu/@27.6954931,85.335472,17z/data=!3m1!4b1!4m6!3m5!1s0x39eb1984ac3145ed:0x41026245602592b1!8m2!3d27.6954884!4d85.3380469!16s%2Fg%2F11gfj56g3q" className="m-0 my-2 text-s"><GeoAltFill /> Mid Baneshwor, Kathmandu</a>
              <p className="m-0 my-2 text-s d-flex gap-2"><TelephoneFill className="mt-1" /> <span> +977-1-4598540, <br /> +977 9851218119, <br /> +9779851333330</span></p>
            </div>
          </div>
          {/* <div>
            <div className="d-flex gap-2 align-items-center">
              <img className="flag-icon" src="/images/flags/singapore.png" alt="" />
              <h5 className="m-0">Singapore</h5>

            </div>
            <div>
              <p className="m-0 my-2 text-s"><Building /> AppHaru PTE. LTD.</p>
              <p className="m-0 my-2 text-s d-flex gap-2"><GeoAltFill className="mt-1" /> <span>05 CECIL Street - #06-02H, <br /> The Octagon</span></p>
              <p className="m-0 my-2 text-s d-flex gap-2"><TelephoneFill className="mt-1" /> <span> +16469804943</span></p>

            </div>
          </div>
          <div>
            <div className="d-flex gap-2 align-items-center">
              <img className="flag-icon" src="/images/flags/usa.png" alt="" />
              <h5 className="m-0">USA</h5>

            </div>
            <div>
              <p className="m-0 my-2 text-s"><Building /> AppHaru, LLC</p>
              <p className="m-0 my-2 text-s d-flex gap-2"><GeoAltFill className="mt-1" /> <span>30 N Gould St, <br /> STE 4000 Sheridan, <br /> WY 82801</span></p>
              <p className="m-0 my-2 text-s d-flex gap-2"><TelephoneFill className="mt-1" /> <span> +9174104512</span></p>
            </div>
          </div> */}
          <div className="social-media-links">
            <div className="footer-contact text-end">

              <div className="footer-email ms-auto">
                <h5 className="" data-aos="fade-right">Email</h5>
                <a
                  href="mailto:billing@appharu.com"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  billing@appharu.com
                </a>
                <a
                  href="mailto:info@appharu.com"
                  data-aos="fade-right"
                  data-aos-delay="400"
                >
                  info@appharu.com
                </a>
              </div>
            </div>
            <ul className="footer-social-links justify-content-end">
              <li
                className="footer-social-link"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <a
                  href="https://www.facebook.com/appharu/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook style={{ color: "#1771E6" }} />
                </a>
              </li>
              <li
                className="footer-social-link"
                data-aos="fade-left"
                data-aos-delay="400"
              >
                <a
                  href="https://np.linkedin.com/company/appharu"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkedin style={{ color: "#0077B5" }} />
                </a>
              </li>
              <li
                className="footer-social-link"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <a
                  href="https://github.com/Appharu"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Github style={{ color: "#0D1117" }} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        &copy; 2017 - 2023 Appharu. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
