import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MessengerCustomerChat from "react-messenger-customer-chat";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { PulseLoader } from "react-spinners";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Navbar/Footer";
import BackToTop from "./Components/BackToTop";
import NotFound from "./Components/NotFound";
import Modal from "./Components/Modal/Modal";

const Home = React.lazy(() => import("./Components/Home/Home"));
const About = React.lazy(() => import("./Components/About/About"));
const Contact = React.lazy(() => import("./Components/Contact/Contact"));
const Services = React.lazy(() => import("./Components/Services/Services"));
const Projects = React.lazy(() => import("./Components/Projects/Projects"));
const Privacy = React.lazy(() => import("./Components/Privacy/Privacy"));
const Financial = React.lazy(() => import("./Components/Financial/Financial"));
const Career = React.lazy(() => import("./Components/Career/Career"));
const FAQ = React.lazy(() => import("./Components/FAQ/Faq"));
const MoreAbout = React.lazy(() => import("./Components/MoreAbout/MoreAbout"));

function App() {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);

  const loader = () => {
    return (
      <div className="page-loader">
        <PulseLoader color={"#828fd9"} size={25} />
      </div>
    );
  };

  return (
    <div className="App">
      <Router>
        {/* <Modal /> */}
        <Navbar />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <React.Suspense fallback={loader()}>
                <Home />
              </React.Suspense>
            }
          />
          <Route
            exact
            path="/contact"
            element={
              <React.Suspense fallback={loader()}>
                <Contact />
              </React.Suspense>
            }
          />
          <Route
            exact
            path="/about-us"
            element={
              <React.Suspense fallback={loader()}>
                <About />
              </React.Suspense>
            }
          />
          <Route
            exact
            path="/services"
            element={
              <React.Suspense fallback={loader()}>
                <Services />
              </React.Suspense>
            }
          />
          <Route
            exact
            path="/projects"
            element={
              <React.Suspense fallback={loader()}>
                <Projects />
              </React.Suspense>
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={
              <React.Suspense fallback={loader()}>
                <Privacy />
              </React.Suspense>
            }
          />
          <Route
            exact
            path="/financial-policy"
            element={
              <React.Suspense fallback={loader()}>
                <Financial />
              </React.Suspense>
            }
          />
          <Route
            exact
            path="/career"
            element={
              <React.Suspense fallback={loader()}>
                <Career />
              </React.Suspense>
            }
          />
          <Route
            exact
            path="/faq"
            element={
              <React.Suspense fallback={loader()}>
                <FAQ />
              </React.Suspense>
            }
          />
          <Route
            exact
            path="/about/more-info"
            element={
              <React.Suspense fallback={loader()}>
                <MoreAbout />
              </React.Suspense>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
      <BackToTop />
      <MessengerCustomerChat
        pageId="335094959947608"
        themeColor="#020657"
        // appId="<APP_ID>"
        // htmlRef="<REF_STRING>"
      />
    </div>
  );
}

export default App;
